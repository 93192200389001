import React from 'react';
import axios from "axios";
// import Cookie from '../../../assets/js/utils/Cookie';
import Api from "../../../assets/js/utils/Api";
import { Helmet } from 'react-helmet';
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import SidebarBusiness from "../../components/User/SidebarBusiness";
import Button from "../../components/CustomButtons/Button";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import GridItem from "../../components/Grid/GridItem";
import AddRuleModal from "../../components/User/AddRuleModal";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DeleteRuleModal from "../../components/User/DeleteRuleModal";

import MaterialIcon from "@mdi/react";
import { mdiAccountPlusOutline } from '@mdi/js';

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
import { helper } from '../../../assets/js/utils/Element.js';

const AttachmentRules = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            totalPages: 1,
            checked: [],
            errorMessage: null,
            addRuleModal: false,
            editMode: false,
            ruleDetails: this.defaultRuleDetails(),
            uploading: false,
            success: false,
            photo: null,
            user: null,
            deleteRuleModal: false
        };
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(user.hasOwnProperty('user_type') && user.user_type !== "business"){
            let redirectUrl = helper.getHomePageUrl(user);
            if(user.level_id === 5){
                redirectUrl = "/user/shared";
            }
            this.history.push(redirectUrl);
        }
        this.loadRules();
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    defaultRuleDetails(){
        return {
            prefix: "",
            prefixOp: 0,
            suffix: "",
            suffixOp: 0,
            directory: "",
            createdate: "",
            emails: "",
            to: false,
            cc: false,
            bcc: false
        }
    }
    onAddRuleModalSuccess(){
        this.loadRules();
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    onAddRuleModal(status){
        if(status === false){
            this.loadRules();
            return;
        }
        this.setState({
            addRuleModal: status,
            ruleDetails: this.defaultRuleDetails()
        });
    }
    onEditModal(status = true, rule){
        if(!status){
            this.loadRules();
            return;
        }
        this.setState({
            addRuleModal: status,
            ruleDetails: {
                id: rule.id,
                prefix: rule.prefix,
                prefixOp: rule.prefix_op,
                suffix: rule.suffix,
                suffixOp: rule.suffix_op,
                directory: rule.directory,
                emails: rule.emails,
                to: ( rule.to === 1 ? true : false ),
                cc: ( rule.cc === 1 ? true : false ),
                bcc: ( rule.bcc === 1 ? true : false )
            },
            editMode: status
        });
    }
    deleteRule(rule){
        let checked = [];
        checked.push(rule.id);
        this.setState({deleteRuleModal: true, checked: checked});
    }
    onModalClose(deleteRuleModal = true){
        let checked = this.state.checked;
        if(deleteRuleModal === false){
            checked = [];
        }
        this.setState({
            deleteRuleModal: deleteRuleModal,
            checked: checked,
        });
    }
    onModalSuccess(){
        const { checked, response } = this.state;
        const source = axios.CancelToken.source();
        
        let ruleId = checked[0];
        
        let requestData = {};

        Api.deleteRule(ruleId, requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });

        let newResponse = [];
        response.map(rule => {
            if(!checked.includes(rule.id)){
                newResponse.push(rule);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteRuleModal: false,
        });
    }
    loadRules(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
        };
        Api.getRules(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            addRuleModal: false,
            editMode: false
        });
    }
    handleToggle(user) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(user.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(user.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked,
        });
    }
    userFirstLetter(user){
        let userFirstLetter = user.first_name.charAt(0);
        return userFirstLetter;
    }
    getOp(rule, name){
        let op = "";
        if(name === 'prefix'){
            if(rule.prefix_op === 1){
                op = "And";
            }else if(rule.prefix_op === 2){
                op = "Or";
            }
        }else if(name === 'suffix'){
            if(rule.suffix_op === 1){
                op = "And";
            }else if(rule.suffix_op === 2){
                op = "Or";
            }
        }
        
        return op;
    }
    getInspect(rule){
        let inspect = "";
        if(rule.to === 1){
            inspect += "To"
        }
        if(rule.to === 1 && rule.cc === 1){
            inspect += " | "
        }
        if(rule.cc === 1){
            inspect += "CC"
        }
        if((rule.to === 1 || rule.cc === 1) && rule.bcc === 1){
            inspect += " | "
        }
        if(rule.bcc === 1){
            inspect += "BCC"
        }

        return inspect;
    }
    getEmails(rule){
        let emails = ""
        emails = (
            rule.emails.map((email, key) => {
                return (
                    <span key={key}>
                        {email}
                    </span>
                );
            })
        )

        return emails;
    }
    getColumns(){
        let columns = [
            {
                Header: "",
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false,
            },
            {
                Header: "Id",
                accessor: "id",
                headerClassName: "hd_id",
                className: "hd_id td_id left",
            },
            {
                Header: "Prefix",
                accessor: "prefix",
                headerClassName: "hd_prefix",
                className: "hd_prefix td_prefix left",
            },
            {
                Header: "PrefixOp",
                accessor: "prefixOp",
                headerClassName: "hd_prefixpop",
                className: "hd_prefixop td_prefixop left",
            },
            {
                Header: "Suffix",
                accessor: "suffix",
                headerClassName: "hd_suffix",
                className: "hd_suffix td_suffix left",
            },
            {
                Header: "SuffixOp",
                accessor: "suffixOp",
                headerClassName: "hd_suffixop",
                className: "hd_suffixop td_suffixop left",
            },
            {
                Header: "Directory",
                accessor: "directory",
                headerClassName: "hd_directory",
                className: "hd_directory td_directory left",
            },
            {
                Header: "Create Date",
                accessor: "createdate",
                headerClassName: "hd_createdate",
                className: "hd_createdate td_createdate left",
            },
            {
                Header: "Inspect",
                accessor: "inspect",
                headerClassName: "hd_inspect",
                className: "hd_inspect td_inspect left",
            },
            {
                Header: "Emails",
                accessor: "emails",
                headerClassName: "hd_emails",
                className: "hd_emails td_emails left",
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false,
                headerClassName: "hd_actions",
                className: "hd_actions td_actions",
            }
        ];
        
        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(rule => {
            let userArray = {
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(rule.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(rule)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                id: rule.id,
                prefix: rule.prefix,
                prefixOp: this.getOp(rule, 'prefix'),
                suffix: rule.suffix,
                suffixOp: this.getOp(rule, 'suffix'),
                directory: rule.directory,
                createdate: rule.created_at,
                inspect: this.getInspect(rule),
                emails: this.getEmails(rule),
                actions: (
                    <>
                        <IconButton
                            color="inherit"
                            onClick={() => this.onEditModal(true, rule)}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            onClick={() => this.deleteRule(rule)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </>
                )
            };
            tableData.push(userArray);
            return null;
        });
        return tableData;
    }
    getRulesContent(response){
        const { classes } = this.props;
        const { loading } = this.state; 

        let content = (
            <div className={classes.content}>
                <div className={classes.controls}>
                    <GridItem>
                        <h4>File Attachment Rules</h4>
                    </GridItem>
                    <div className={classes.controlButtons}>
                        <Button color="transparent" onClick={() => this.onAddRuleModal(true)}>
                            <MaterialIcon path={mdiAccountPlusOutline} className="MuiSvgIcon-root" />
                            Add a rule
                        </Button>
                    </div>
                </div>
                <div className={classes.usersContainer+" "+classes.rulesContainer}>
                    <div className={classes.users}>
                        {
                            loading ?
                                <LoaderComponent color="custom" align="center" />
                            :
                                <ReactTable
                                    columns={this.getColumns()}
                                    data={this.getTableData()}
                                    pageSizeOptions={[500]}
                                    defaultPageSize={50000000000}
                                    showPaginationTop={false}
                                    minRows={0}
                                    showPaginationBottom={false}
                                    className={"-striped -highlight "+classes.filesTable+" usersTable"}
                                    getNoDataProps={() => this.getNoDataProps()}
                                />
                        }
                    </div>
                </div>
            </div>
        );
        
        return (
            content 
        );
    }
    render() {
        const { classes } = this.props;
        const { response, addRuleModal, editMode, ruleDetails, deleteRuleModal } = this.state;
        
        const { authorized, user } = this.store.getState();
        if(!authorized || user.level_id === 5){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }   
        
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.sidebar}>
                        <SidebarBusiness store={this.store} />
                    </div>
                    {
                        this.getRulesContent(response)
                    }
                    {
                        addRuleModal ?
                            <AddRuleModal open={addRuleModal} editMode={editMode} ruleDetails={ruleDetails} onClose={() => this.onAddRuleModal(false)} onSuccess={() => this.onAddRuleModalSuccess()} store={this.store} />
                        :
                            <></>
                    }
                    <DeleteRuleModal open={deleteRuleModal} onClose={() => this.onModalClose(false)} onSuccess={() => this.onModalSuccess()} />
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

AttachmentRules.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(AttachmentRules);
