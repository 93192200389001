import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "../CustomButtons/Button.jsx";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Menu, Person, Receipt, Group } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

import sidebarStyle from "../../../assets/jss/sidebarStyle";

class AccountSidebar extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            isActive: false
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }
    getPlansMenu(){
        const { classes } = this.props;
        let menuItems = [];
        menuItems.push(<RouterLink to={"/user/account"}>
            <Button color="transparent" className={classes.navLink}>Manage Subscriptions</Button>
        </RouterLink>);
        menuItems.push(<RouterLink to={"/user/plans"}>
            <Button color="transparent" className={classes.navLink}>My Plans</Button>
        </RouterLink>);
        menuItems.push(
            <RouterLink 
                to={{
                    pathname: "/user/account",
                    section: "billing-history"
                }}
            >
                    <Button color="transparent" className={classes.navLink}>Billing History</Button>
            </RouterLink>
        );
        menuItems.push(
            <RouterLink 
                to={{
                    pathname: "/user/account",
                    section: "payment-methods"
                }}
            >
                <Button color="transparent" className={classes.navLink}>Payment Methods</Button>
            </RouterLink>
        );
        return menuItems;
    }
    getProfileMenu(){
        const { classes } = this.props;
        const { user } = this.store.getState();
        
        let menuItems = [];
        menuItems.push(<RouterLink to={"/user/profile"}>
            <Button color="transparent" className={classes.navLink}>Profile</Button>
        </RouterLink>);
        if(user.user_type === "business"){
            menuItems.push(<RouterLink to={"/user/business"}>
                <Button color="transparent" className={classes.navLink}>Business Profile</Button>
            </RouterLink>);
        }
        if(user.user_type === "business"){
            menuItems.push(<RouterLink to={"/user/ad-management"}>
                <Button color="transparent" className={classes.navLink}>Ad Management</Button>
            </RouterLink>);
        }
        menuItems.push(<RouterLink to={"/user/password"}>
            <Button color="transparent" className={classes.navLink}>Password</Button>
        </RouterLink>);
        menuItems.push(<RouterLink to={"/user/accounts/license"}>
            <Button color="transparent" className={classes.navLink}>Connected Account(s)</Button>
        </RouterLink>);
        menuItems.push(<RouterLink to={"/user/privacy"}>
            <Button color="transparent" className={classes.navLink}>Privacy</Button>
        </RouterLink>);
        if(user.hasOwnProperty('settings') && user.settings.hasOwnProperty("exportFunction") && user.settings.exportFunction === "1"){
            menuItems.push(<RouterLink to={"/user/export"}>
                <Button color="transparent" className={classes.navLink}>Export</Button>
            </RouterLink>);
            menuItems.push(<RouterLink to={"/user/download"} className={"dropdown-submenu"}>
                <Button color="transparent" className={classes.navLink}>Downloads</Button>
            </RouterLink>)
            menuItems.push(<RouterLink to={"/user/history"} className={"dropdown-submenu"}>
                <Button color="transparent" className={classes.navLink}>History</Button>
            </RouterLink>);
        }
        if(user.hasOwnProperty('businessUser') && user.businessUser !== null && user.member_type !== 'business'){
            
        }else{
            menuItems.push(<RouterLink to={"/user/extensions"}>
                <Button color="transparent" className={classes.navLink}>Extensions</Button>
            </RouterLink>);
        }
        return menuItems;
    }
    toggleMenu(){
        this.setState({
            isActive: !this.state.isActive
        })
    }
    render(){
        let isDesktop = isWidthUp('md', this.props.width);
        const { classes } = this.props;
        const {isActive} = this.state;

        return (
                <div className={classes.container}>
                    {
                        isDesktop === false ?
                            <div className={classes.toggleMenu}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.toggleMenu}
                                >
                                    <Menu />
                                </IconButton>
                            </div>
                        :
                        <></>
                    }
                    <div className={(isDesktop ? "" : (classes.collapse+" "+(isActive ? "active": "")))}>
                        <List className={classes.list + " " + classes.mlAuto}>
                            <ListItem className={classes.listItem}>
                                <RouterLink to="/user/profile">
                                    <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                        <Person />Profile
                                    </Button>
                                </RouterLink>
                                <List className={classes.list + " " + classes.mlAuto}>
                                    {
                                        this.getProfileMenu().map((item, key) => {
                                            return (
                                                <ListItem key={key} className={classes.listItem}>
                                                    {item}
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <RouterLink to="/user/referrals">
                                    <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                        <Group />Refer a Friend
                                    </Button>
                                </RouterLink>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                                <RouterLink to="/user/account">
                                    <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                        <Receipt />Billing
                                    </Button>
                                </RouterLink>
                                <List className={classes.list + " " + classes.mlAuto}>
                                    {
                                        this.getPlansMenu().map((item, key) => {
                                            return (
                                                <ListItem key={key} className={classes.listItem}>                                                    
                                                    {item}
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </ListItem>
                        </List>
                    </div>
                </div>
        )
    }
}

AccountSidebar.defaultProps = {
    align: "center",
    color: "primary"
}
AccountSidebar.propTypes = {
    classes: PropTypes.object
};

export default withWidth()(withStyles(sidebarStyle)(AccountSidebar));