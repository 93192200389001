import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Sidebar from "../../components/User/AccountSidebar";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import LoaderComponent from '../../components/Loader'
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import DatePicker from "../../components/CustomInput/DatePicker";
import CustomFileInput from "../../components/CustomFileInput/CustomFileInput";
import RichTextEditor from 'react-rte';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
import { helper } from '../../../assets/js/utils/Element.js';

const Business = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        const { user } = this.store.getState();
        let values = Api.prepareMemberObject(user);
        this.state = {
            values: values,
            businessInfo: null,
            editInfo: false,
            savingInfo: false,
            showError: false,
            errorMessage: "",
            validation: {
                businessType: '',
                keywords: '',
                employeesCount: '',
                occupation: '',
                countryId: '',
                isValid: false
            },
            countries: [],
            states: [],
            cities: [],
            loadingCountries: false,
            loadingStates: false,
            loadingCities: false,
            success: false,
            uploading: false,
            backgroundUploading: false,
            internalBackgroundUploading: false,
            aboutBusiness: RichTextEditor.createEmptyValue(),
            downloadStyle: 'stretched'
        }

        this.editUserInfo = this.editUserInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveUserInfo = this.saveUserInfo.bind(this);
        
        this.businessTypes = Api.getBusinessTypes();
        this.employeesNo = ['1-5', '6-10', '11-20', '21-50', '51-100', '100+'];
        this.occupationOptions = Api.getOccupationOptions();
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }        
        if(authorized){
            if(user.hasOwnProperty('user_type') && user.user_type !== "business"){
                let redirectUrl = helper.getHomePageUrl(user);
                if(user.level_id === 5){
                    redirectUrl = "/user/shared";
                }
                this.history.push(redirectUrl);
            }

            this.loadBusinessInfo();                
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(prevState.businessInfo === null && this.state.businessInfo !== null){
            this.loadCountries();
            if(this.state.businessInfo.country_id){
                this.loadStates(this.state.businessInfo.country_id, true)
            }
            if(this.state.businessInfo.state_id){
                this.loadCities(this.state.businessInfo.state_id, true)
            }
        }
    }
    loadBusinessInfo(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loading: true
        });
        let requestData = {
            id: this.state.values.id
        }
        Api.getBusinessInfo(requestData, source).then(data => {
            const about = (data.about ? RichTextEditor.createValueFromString(data.about, "html") : RichTextEditor.createEmptyValue());
            const downloadStyle = data.download_style;
            that.setState({
                businessInfo: data,
                loading: false,
                error: false,
                aboutBusiness: about,
                downloadStyle: downloadStyle
            });
        }).catch(err => {
            console.log(err);
            if(err.message !== "Request Cancelled"){
                this.setState({
                    loading: false,
                    error: true,
                    errorMessage: err.message
                });
            }
        });
    }
    editUserInfo(editInfo = true){
        this.setState({editInfo: editInfo});
    }
    saveUserInfo(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            editInfo: false, 
            savingInfo: true,
            showError: false
        });
        this.updateUser();
    }
    validateForm(){
        const { businessInfo } = this.state;

        let validation = {
            businessType: "success",
            keywords: "success",
            employeesCount: "success",
            occupation: "success",
            countryId: "success",
            isValid: true
        };
        if(businessInfo.business_type.length <= 0){
            validation.businessType = "error";
            validation.isValid = false;
        }
        if(businessInfo.keywords.length <= 0){
            validation.keywords = "error";
            validation.isValid = false;
        }
        if(businessInfo.employees_count.length <= 0){
            validation.employeesCount = "error";
            validation.isValid = false;
        }
        if(businessInfo.occupation.length <= 0){
            validation.occupation = "error";
            validation.isValid = false;
        }
        if(businessInfo.country_id.length <= 0){
            validation.countryId = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    updateUser(){
        const { user } = this.store.getState();
        let values = Object.assign({}, this.state.businessInfo);
        const { aboutBusiness, downloadStyle } = this.state;

        const requestData = {
            businessType: values.business_type,
            keywords: values.keywords,
            employeesCount: values.employees_count,
            establishedDate: values.established_date,
            website: values.website,
            dob: values.dob,
            occupation: values.occupation,
            address1: values.address1,
            address2: values.address2,
            countryId: values.country_id,
            stateId: values.state_id,
            cityId: values.city_id,
            about: aboutBusiness.toString("html"),
            downloadStyle: downloadStyle
        };

        const source = axios.CancelToken.source();
        Api.updateBusinessInfo(user.id, requestData, source).then((data) => {
            this.setState({
                savingInfo: false, 
                businessInfo: data
            });
            // let newUser = Api.prepareMemberObject(data.user);
            // this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            this.setState({
                savingInfo: false, 
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    handleChange(event, name) {
        this.setState({
            businessInfo: {
                ...this.state.businessInfo, 
                [name]: event.target.value
            }
        });
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                businessInfo: {
                    ...this.state.businessInfo, 
                    [name]: parsedDate.toISOString()
                }
           });
        }catch(e){ console.log(e); }        
    }
    handleFileChange(e, name){
        let photo = e.target.files[0];

        var requestData = new FormData();
        requestData.append('photo', photo);

        const source = axios.CancelToken.source();
        this.setState({
            businessInfo: {
                ...this.state.businessInfo, 
                [name]: photo
            },
            uploading: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.uploadBusinessPhoto(requestData, source).then(data => {
            that.setState({
                success: true,
                uploading: false,
                businessInfo: data.businessUser
            });
            let newUser = Api.prepareMemberObject(data);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    uploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    handleBackgroundPhotoChange(e, name){
        let photo = e.target.files[0];

        var requestData = new FormData();
        requestData.append('photo', photo);

        const source = axios.CancelToken.source();
        this.setState({
            businessInfo: {
                ...this.state.businessInfo, 
                [name]: photo
            },
            backgroundUploading: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.uploadBusinessBackgroundPhoto(requestData, source).then(data => {
            that.setState({
                success: true,
                backgroundUploading: false,
                businessInfo: data
            });
            // let newUser = Api.prepareMemberObject(data);
            // this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    backgroundUploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    onInternalBackgroundChange(e, name){
        let photo = e.target.files[0];

        var requestData = new FormData();
        requestData.append('photo', photo);

        const source = axios.CancelToken.source();
        this.setState({
            businessInfo: {
                ...this.state.businessInfo, 
                [name]: photo
            },
            internalBackgroundUploading: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.uploadInternalBackgroundPhoto(requestData, source).then(data => {
            that.setState({
                success: true,
                internalBackgroundUploading: false,
                businessInfo: data
            });
            let { user } = this.store.getState();
            user.businessUser = data;
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(user));
            let newUser = Api.prepareMemberObject(user);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    internalBackgroundUploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    removeBusinessPhoto(e, name){
        const source = axios.CancelToken.source();
        this.setState({
            businessInfo: {
                ...this.state.businessInfo, 
                [name]: ''
            },
            cancelToken: source,
            showError: false,
        });
        let requestData = {
            name: name
        };
        const that = this;
        Api.removeBusinessPhoto(requestData, source).then(data => {
            that.setState({
                success: true,
                uploading: false,
                businessInfo: data.businessUser
            });
            let newUser = Api.prepareMemberObject(data);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    uploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onCountryChange(e){
        const country = e.target.value;
        this.loadStates(country);
    }
    loadStates(country, onPageLoad = false){
        let country_id = this.state.businessInfo.country_id;
        if(country_id === country && onPageLoad === false){
            return;
        }
        let stateIdState = "";
        let cityIdState = "";
        if(onPageLoad === true){
            stateIdState = this.state.businessInfo.state_id;
            cityIdState = this.state.businessInfo.city_id;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            businessInfo: {
                ...this.state.businessInfo,
                country_id: country,
                state_id: stateIdState,
                city_id: cityIdState
            },
            cancelToken: source,
            loadingStates: true,
            states: [],
            cities: [],
        });
        Api.getStates(country, source).then(data => {
            that.setState({
                states: data.response,
                loadingStates: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onStateChange(e){
        const state = e.target.value;
        this.loadCities(state);
    }
    loadCities(state, onPageLoad = false){
        let state_id = this.state.businessInfo.state_id;
        if(state_id === state && onPageLoad === false){
            return;
        }

        let cityIdState = "";
        if(onPageLoad === true){
            cityIdState = this.state.businessInfo.city_id;
        }

        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        const { country_id } = this.state.businessInfo;
        that.setState({
            businessInfo: {
                ...this.state.businessInfo,
                state_id: state,
                city_id: cityIdState,
            },
            cancelToken: source,
            loadingCities: true,
            cities: [],
        });
        Api.getCities(country_id, state, source).then(data => {
            that.setState({
                cities: data.response,
                loadingCities: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    getConfig(){
        const toolbarConfig = {
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
              {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
              {label: 'Italic', style: 'ITALIC'},
              {label: 'Underline', style: 'UNDERLINE'}
            ],
            BLOCK_TYPE_DROPDOWN: [
              {label: 'Normal', style: 'unstyled'},
              {label: 'Heading Large', style: 'header-one'},
              {label: 'Heading Medium', style: 'header-two'},
              {label: 'Heading Small', style: 'header-three'}
            ],
            BLOCK_TYPE_BUTTONS: [
              {label: 'UL', style: 'unordered-list-item'},
              {label: 'OL', style: 'ordered-list-item'}
            ]
        };
        return toolbarConfig;
    }
    handleAboutChange(content, name){
        this.setState({
            [name]: content
        });
    }
    handledownloadStyle(e){
        this.setState({
            'downloadStyle': (e.target.checked ? 'boxed' : 'stretched')
        });
    }
    render() {
        const { classes } = this.props;
        const { editInfo, businessInfo, savingInfo, validation, showError, cities, states, countries, loadingCountries, loadingCities, loadingStates,
            backgroundUploading, uploading, loading, aboutBusiness, downloadStyle, internalBackgroundUploading } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        let userFirstLetter = user.first_name.charAt(0);
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.profileContainer+" "+classes.businessInfoContainer}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    <div className={classes.content}>
                        <GridItem className={classes.main} xs={12} sm={12} md={8} lg={6}>
                            <h3 className={classes.title}>Business Info</h3>
                            {
                                businessInfo ?
                                    <>
                                        <h3 className="logo-title">Business Logo</h3>
                                        <div className={classes.userFirstLetter+" user-image"}>
                                            <span className={"user-icon "+(businessInfo.profile_photo_url ? 'user-picture': '')}>
                                                {
                                                    uploading === true ?
                                                        <LoaderComponent color="custom" align="center" />
                                                    : businessInfo.profile_photo_url ?
                                                        <img className={"profile-picture"} src={businessInfo.profile_photo_url} alt={userFirstLetter} />
                                                    : 
                                                        <></>   
                                                }
                                            </span>
                                            <div className={"photos-action"}>
                                                {
                                                    businessInfo.profile_photo_url ?
                                                        <div className={"removePhoto"}>
                                                            <Button color="custom" round={true} size={"sm"} onClick={(e) => this.removeBusinessPhoto(e, 'profile_photo_url')}>Remove Photo</Button>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                                <div className={"uploadPhotoInput"}>
                                                    <CustomFileInput
                                                        id="input-photo"                            
                                                        onChange={(e) => this.handleFileChange(e,'profile_photo_url')}
                                                        endButton={{
                                                            buttonProps: {
                                                                round: true,
                                                                color: "custom",
                                                                fileButton: true,
                                                                size: "sm"
                                                            },
                                                            text: businessInfo.profile_photo_url ? "Change Photo" : "Upload Photo"
                                                        }}
                                                        inputProps={{
                                                            value: businessInfo.profile_photo_url,
                                                            placeholder: "Photo"
                                                        }}
                                                        color="custom"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <p className="logo-note">Recommender Logo Size: Max Height 60px and Max Width 190px</p>

                                        <h3 className="logo-title">Business Background Photo</h3>
                                        <div className={classes.userFirstLetter+" user-image background-image"}>
                                            <span className={"user-icon "+(businessInfo.background_photo ? 'user-picture': '')}>
                                                {
                                                    backgroundUploading === true ?
                                                        <LoaderComponent color="custom" align="center" />
                                                    : businessInfo.background_photo ?
                                                        <img className={"profile-picture"} src={businessInfo.background_photo} alt={userFirstLetter} />
                                                    : 
                                                        <></>   
                                                }
                                            </span>
                                            <div className={"photos-action"}>
                                                {
                                                    businessInfo.background_photo ?
                                                        <div className={"removePhoto"}>
                                                            <Button color="custom" round={true} size={"sm"} onClick={(e) => this.removeBusinessPhoto(e, 'background_photo')}>Remove Photo</Button>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                                <div className={"uploadPhotoInput"}>
                                                    <CustomFileInput
                                                        id="input-photo"                            
                                                        onChange={(e) => this.handleBackgroundPhotoChange(e,'background_photo')}
                                                        endButton={{
                                                            buttonProps: {
                                                                round: true,
                                                                color: "custom",
                                                                fileButton: true,
                                                                size: "sm"
                                                            },
                                                            text: businessInfo.background_photo ? "Change Photo" : "Upload Photo"
                                                        }}
                                                        inputProps={{
                                                            value: businessInfo.background_photo,
                                                            placeholder: "Photo"
                                                        }}
                                                        color="custom"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <h3 className="logo-title">Internal Background Photo</h3>
                                        <div className={classes.userFirstLetter+" user-image background-image"}>
                                            <span className={"user-icon "+(businessInfo.internal_background_photo ? 'user-picture': '')}>
                                                {
                                                    internalBackgroundUploading === true ?
                                                        <LoaderComponent color="custom" align="center" />
                                                    : businessInfo.internal_background_photo ?
                                                        <img className={"profile-picture"} src={businessInfo.internal_background_photo} alt={userFirstLetter} />
                                                    : 
                                                        <></>   
                                                }
                                            </span>
                                            <div className={"photos-action"}>
                                                {
                                                    businessInfo.internal_background_photo ?
                                                        <div className={"removePhoto"}>
                                                            <Button color="custom" round={true} size={"sm"} onClick={(e) => this.removeBusinessPhoto(e, 'internal_background_photo')}>Remove Photo</Button>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                                <div className={"uploadPhotoInput"}>
                                                    <CustomFileInput
                                                        id="input-photo"                            
                                                        onChange={(e) => this.onInternalBackgroundChange(e,'internal_background_photo')}
                                                        endButton={{
                                                            buttonProps: {
                                                                round: true,
                                                                color: "custom",
                                                                fileButton: true,
                                                                size: "sm"
                                                            },
                                                            text: businessInfo.internal_background_photo ? "Change Photo" : "Upload Photo"
                                                        }}
                                                        inputProps={{
                                                            value: businessInfo.internal_background_photo,
                                                            placeholder: "Photo"
                                                        }}
                                                        color="custom"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div>

                                            {
                                                editInfo === false && savingInfo === false ?
                                                    <div className={"editButton"}>
                                                        <Button color="custom" onClick={() => this.editUserInfo()}>Edit</Button>
                                                    </div>
                                                :
                                                savingInfo ? <LoaderComponent color="custom" align="left" /> : <></>
                                            }                    
                                            {
                                                editInfo === true ?
                                                    <div className={"saveButtons"}>
                                                        <Button color="white" onClick={() => this.editUserInfo(false)}>
                                                            Cancel
                                                        </Button>
                                                        <Button color="custom" onClick={() => this.saveUserInfo()}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                :
                                                    <></>
                                            }
                                        </div>
                                        <div className={"userInfo "+(editInfo === false?classes.viewInfo:'')}>
                                            {
                                                showError ?
                                                    this.renderErrorMessages()
                                                :
                                                <></>
                                            }
                                            <CustomSelect 
                                                success={validation.businessType === "success"}
                                                error={validation.businessType === "error"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelText="Business Type"
                                                selectProps={{
                                                    onChange: (e) => this.handleChange(e,"business_type"),
                                                    value: businessInfo.business_type ? businessInfo.business_type : ""
                                                }}
                                                inputProps={{
                                                    name: "business_type",
                                                    id: "input-businessType",
                                                    className: classes.alignLeft,
                                                    disabled: !editInfo,
                                                }}
                                                items={this.businessTypes}
                                                itemLabel="label"
                                                itemValue="id"
                                            />
                                            <CustomInput
                                                success={validation.keywords === "success"}
                                                error={validation.keywords === "error"}
                                                id="outlined-businessInfo"
                                                labelText="Product or Service Keywords"                                    
                                                inputProps={{
                                                    value: businessInfo.keywords ? businessInfo.keywords : "",
                                                    onChange: (e) => this.handleChange(e, 'keywords'),
                                                    name: "keywords",
                                                    disabled: !editInfo,
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />
                                            <CustomSelect 
                                                success={validation.employeesCount === "success"}
                                                error={validation.employeesCount === "error"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelText="Number of Employees"
                                                selectProps={{
                                                    onChange: (e) => this.handleChange(e,"employees_count"),
                                                    value: businessInfo.employees_count ? businessInfo.employees_count : ""
                                                }}
                                                inputProps={{
                                                    name: "employees_count",
                                                    id: "input-employeescount",
                                                    className: classes.alignLeft,
                                                    disabled: !editInfo,
                                                }}
                                                items={this.employeesNo}
                                                itemLabel="label"
                                                itemValue="id"
                                            />
                                            <DatePicker
                                                labelText={"Date Business Established"}
                                                id="input-establishedDate"
                                                value={businessInfo.established_date ? businessInfo.established_date : ""}
                                                disableFuture={true}
                                                onChange={(date) => this.handleDateChange(date, 'established_date')}
                                                inputProps={{
                                                    disabled: !editInfo
                                                }}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                            />
                                            <CustomInput
                                                id="input-website"
                                                labelText="Website"
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e,'website'),
                                                    name: "website",
                                                    value: businessInfo.website ? businessInfo.website : "",
                                                    disabled: !editInfo
                                                }}                                    
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                            />
                                            <DatePicker
                                                labelText={"Date of Birth"}
                                                id="input-dob"
                                                value={businessInfo.dob ? businessInfo.dob : ""}
                                                disableFuture={true}
                                                onChange={(date) => this.handleDateChange(date, 'dob')}
                                                inputProps={{
                                                    disabled: !editInfo
                                                }}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                            />
                                            <CustomSelect 
                                                success={validation.occupation === "success"}
                                                error={validation.occupation === "error"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                labelText="What is Your Occupation?"
                                                selectProps={{
                                                    onChange: (e) => this.handleChange(e,"occupation"),
                                                    value: businessInfo.occupation ? businessInfo.occupation : ""
                                                }}
                                                inputProps={{
                                                    name: "occupation",
                                                    id: "input-occupation",
                                                    className: classes.alignLeft
                                                }}
                                                items={this.occupationOptions}
                                            />
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                id="input-address1"
                                                labelText="Address1"
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e, 'address1'),
                                                    name: "address1",
                                                    value: businessInfo.address1 ? businessInfo.address1 : "",
                                                    disabled: !editInfo
                                                }}
                                            />
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                id="input-address2"
                                                labelText="Address2"
                                                inputProps={{
                                                    onChange: (e) => this.handleChange(e, 'address2'),
                                                    name: "address2",
                                                    value: businessInfo.address2 ? businessInfo.address2 : "",
                                                    disabled: !editInfo
                                                }}
                                            />
                                            {
                                                countries.length > 0 ?
                                                    <CustomSelect 
                                                        success={validation.countryId === "success"}
                                                        error={validation.countryId === "error"}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        labelText="Country"
                                                        selectProps={{
                                                            onChange: (e) => this.onCountryChange(e),
                                                            value: businessInfo.country_id ? businessInfo.country_id : ""
                                                        }}
                                                        inputProps={{
                                                            name: "country_id",
                                                            id: "input-countryId",
                                                            className: classes.alignLeft,
                                                            disabled: !editInfo
                                                        }}
                                                        items={countries}
                                                        itemLabel="name"
                                                        itemValue="id"
                                                    />
                                                :
                                                    loadingCountries ?
                                                        <LoaderComponent color="custom" align="left" />
                                                    :
                                                    <></>
                                            }
                                            {
                                                states.length > 0 ?
                                                    <CustomSelect 
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        labelText="State / Province"
                                                        selectProps={{
                                                            onChange: (e) => this.onStateChange(e),
                                                            value: businessInfo.state_id ? businessInfo.state_id : ""
                                                        }}
                                                        inputProps={{
                                                            name: "state_id",
                                                            id: "input-stateId",
                                                            className: classes.alignLeft,
                                                            disabled: !editInfo
                                                        }}
                                                        items={states}
                                                        itemLabel="name"
                                                        itemValue="id"
                                                    />
                                                :
                                                    loadingStates ?
                                                        <LoaderComponent color="custom" align="left" />
                                                    :
                                                    <></>
                                            }
                                            {
                                                cities.length > 0 ?
                                                    <CustomSelect 
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        labelText="City"
                                                        selectProps={{
                                                            onChange: (e) => this.handleChange(e, "city_id"),
                                                            value: businessInfo.city_id ? businessInfo.city_id : ""
                                                        }}
                                                        inputProps={{
                                                            name: "city_id",
                                                            id: "input-cityId",
                                                            className: classes.alignLeft,
                                                            disabled: !editInfo
                                                        }}
                                                        items={cities}
                                                        itemLabel="name"
                                                        itemValue="id"
                                                    />
                                                :
                                                    loadingCities ?
                                                        <LoaderComponent color="custom" align="left" />
                                                    :
                                                    <></>
                                            }
                                                    
                                        </div>
                                        <div className={classes.editor}>
                                            <label className={classes.aboutLabel}>About</label>
                                            <RichTextEditor 
                                                toolbarConfig={this.getConfig()} 
                                                onChange={(content) => this.handleAboutChange(content, "aboutBusiness")}
                                                value={aboutBusiness}
                                                readOnly={!editInfo}
                                                toolbarClassName="sd-editor-toolbar"
                                            />
                                        </div>
                                        <div className={classes.downloadStyle}>
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox                                                
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handledownloadStyle(e)}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={(downloadStyle === "boxed")}
                                                        disabled={!editInfo}
                                                    />
                                                }
                                                label="Boxed download page style"
                                            />
                                        </div>
                                        <div>
                                            {
                                                editInfo === true ?
                                                    <div className={"saveButtons saveButtonsBottom"}>
                                                        <Button color="white" onClick={() => this.editUserInfo(false)}>
                                                            Cancel
                                                        </Button>
                                                        <Button color="custom" onClick={() => this.saveUserInfo()}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                :
                                                    editInfo === false && savingInfo === false ?
                                                        <div className={"editButton editButtonBottom"}>
                                                            <Button color="custom" onClick={() => this.editUserInfo()}>Edit</Button>
                                                        </div>
                                                    :
                                                    savingInfo ? <LoaderComponent color="custom" align="left" /> : <></>
                                            }
                                        </div>
                                    </>
                                : loading === true ? 
                                    <LoaderComponent color="custom" align="left" />
                                :
                                    <></>
                            }
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Business.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Business);
