import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import { helper } from "../../../assets/js/utils/Element";
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Sidebar from "../../components/User/AccountSidebar";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Button from "../../components/CustomButtons/Button";
import MaterialIcon from "@mdi/react";
import { mdiContentCopy, mdiSend, mdiCheck, mdiClose, mdiStarFourPointsOutline } from '@mdi/js';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LoaderComponent from "../../components/Loader";
import ReactTable from "react-table";
import RefreshIcon from "@material-ui/icons/Refresh";
import moment from 'moment';

import withStyles from "@material-ui/core/styles/withStyles";
import userReferralsPageStyle from "../../../assets/jss/user/userReferralsPageStyle.jsx";

const emailRegex = /\S+@\S+\.\S+/;

const Referrals = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            email: "",
            emails: [],
            snackbarOpen: false,
            limit: 100,
            totalItemCount: 0,
            response: null,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            page: 1,
            cancelToken: null,
            saving: false,
            errors: null,
            spaceEarned: 0
        };

        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }
        this.loadReferrals();
    }
    loadReferrals(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 10,
        };
        Api.getReferrals(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                spaceEarned: data.spaceEarned
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            cancelToken: source,
            page: page,
        });
    }
    getReferralLink(){
        const { authorized, user } = this.store.getState();
        const origin = window.location.origin;
        if(!authorized){
            return "";
        }
        return origin+"/referral/signup/"+user.id;
    }
    handleTagChange(event, name) {
        let value = event.target.value;
        let emails = this.state.emails;
        if(event.keyCode === 13 && emailRegex.test(value)){
            emails.push(value);
            value = '';
        }
        this.setState({
            [name]: value,
            emails: emails,
            errors: null,
        });
    }
    onTagRemove(tag){
        let emails = this.state.emails;
        const index = emails.indexOf(tag);
        if(index === -1){
            return;
        }
        emails.splice(index, 1);
        this.setState({
            emails: emails
        });
    }
    onCopyLink(referralLink){
        helper.copyTextToClipboard(referralLink);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    sendInvites(){
        const { email } = this.state;
        let emails = Object.assign([], this.state.emails);
        if(email.length > 0 && emailRegex.test(email)){
            emails.push(email);
        }
        if(emails.length <= 0){
            return;
        }

        const source = axios.CancelToken.source();
        const requestData = {
            emails: emails,
        };
        Api.inviteUsers(requestData, source).then(data => {
            this.setState({
                email: "",
                emails: [],
                totalItemCount: data.totalItemCount,
                response: data.response,
                totalPages: data.totalPages,
                loading: false,
                loadingMore: false,
                errors: data.errors,
                saving: false,
                spaceEarned: data.spaceEarned
            });
        }).catch(err => {
            this.setState({
                loading: false,
                loadingMore: false,
                errors: [{email: "", message: err.message}],
                saving: false
            });
        });

        this.setState({
            cancelToken: source,
            saving: true,
            errors: null
        });
    }
    renderErrorMessages(){
        const { classes } = this.props;
        const { errors } = this.state;
        if(errors === null || errors.length <= 0){
            return null;
        }
        let errorMessages = [];
        errors.map((error, key) => {
            errorMessages.push(
                <p className={classes.errorMessages} key={key}>{error.message}</p>
            )
            return null;
        });
        return errorMessages;
    }
    getFormatedDate(date){
        if(!date || date.length <= 0){
            return "No Date";
        }
        return moment(date).format("MM/DD/YYYY h:mm A");
    }
    getStatus(referral){
        let newStatus = '';
        switch(referral.status){
            case 'invited':
                newStatus = (
                    <>
                        <MaterialIcon path={mdiSend} className="MuiSvgIcon-root" />
                        Invitation sent
                    </>
                );
                break;
            case 'ineligible':
                newStatus = (
                    <>
                        <MaterialIcon path={mdiClose} className="MuiSvgIcon-root" />
                        Ineligible
                    </>
                );
                break;
            case 'approved':
                newStatus = (
                    <>
                        <MaterialIcon path={mdiCheck} className="MuiSvgIcon-root" />
                        500 MB
                    </>
                );
                break;
            default:
                break;
        }
        return newStatus;
    }
    getTableData(){
        const { response } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(referral => {
            let linkArray = {
                id: referral.id,
                email: referral.email,
                status: this.getStatus(referral),
                updated_at: this.getFormatedDate(referral.updated_at),
            };
            tableData.push(linkArray);
            return null;
        });
        return tableData;
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                // onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getColumns(){
        let columns = [
            {
                Header: "Recipient email",
                accessor: "email",
                headerClassName: "hd_email",
                className: "hd_email td_email",
            },
            {
                Header: "Updated",
                accessor: "updated_at",
                headerClassName: "hd_updated_at",
                className: "hd_updated_at td_updated_at",
            },
            {
                Header: "Status",
                accessor: "status",
                headerClassName: "hd_status",
                className: "hd_status td_status",
            }
        ];

        return columns;
    }
    render() {
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        const { email, emails, saving, loading, loadingMore, page, totalPages, limit, spaceEarned } = this.state;
        const referralLink = this.getReferralLink();
        const tableData = this.getTableData();

        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }

        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.profileContainer}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    <div className={classes.content+" userInfo"}>
                        <GridItem className={classes.main} xs={12} sm={12} md={8} lg={6}>
                            <h3 className={classes.title}>Refer a Friend</h3>
                            <p>Earn 500 MB for every friend who joins Sendlinx. They can sign up for any plan and you get your bonus space the minute they join Sendlinx.</p>
                            <div className={classes.inputButtons}>
                                <CustomInput
                                    id="outlined-link"
                                    labelText="Copy your invite link"                                    
                                    inputProps={{
                                        value: referralLink,
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                                <Button color="custom" size="sm" onClick={() => this.onCopyLink(referralLink)}>
                                    <MaterialIcon path={mdiContentCopy} className="MuiSvgIcon-root" />
                                    Copy
                                </Button>
                            </div>
                            <div className={classes.inputButtons+" "+classes.sendInviteButtons}>
                                <CustomInput
                                    id="outlined-email"
                                    labelText="Email your invite"                                    
                                    inputProps={{
                                        value: email,
                                        onChange: (e) => this.handleTagChange(e, 'email'),
                                        onKeyUp: (e) => this.handleTagChange(e, 'email'),
                                        name: "email",
                                        type: "email",
                                        placeholder: (emails.length > 0 ? "" : "email@email.com")
                                    }}
                                    onTagRemove={(tag) => this.onTagRemove(tag)}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    tags={emails}
                                />
                                <Button color="custom" size="sm" onClick={() => this.sendInvites()} disabled={saving || (emails.length <= 0 && email.length <= 0) || (email.length > 0 && !emailRegex.test(email))}>
                                    {
                                        saving ?
                                            <LoaderComponent color="white" align="center" size={17} />
                                        :
                                        <MaterialIcon path={mdiSend} className="MuiSvgIcon-root" />
                                    }
                                    Send
                                </Button>
                            </div>
                            {this.renderErrorMessages()}
                        </GridItem>
                        <GridItem className={classes.main} xs={12} sm={12} md={12} lg={12}>
                            <h3 className={classes.spaceEarned}>
                                <p>Total bonus space earned</p>
                                <p>{helper.getFormatedSize(spaceEarned)}</p>
                            </h3>
                            <div className={classes.referrals}>
                                {
                                    loading ?
                                        <LoaderComponent color="custom" align="left" />
                                    :
                                        <>
                                            <div className={classes.referralsHeading}>
                                                <span><MaterialIcon path={mdiStarFourPointsOutline} className="MuiSvgIcon-root" />From referrals</span>
                                                <span>{helper.getFormatedSize(spaceEarned)} of 16 GB</span>
                                            </div>
                                            <ReactTable
                                                columns={this.getColumns()}
                                                data={tableData}
                                                pageSizeOptions={[500]}
                                                defaultPageSize={limit}
                                                showPaginationTop={false}
                                                minRows={0}
                                                showPaginationBottom={false}
                                                className={"-striped -highlight "+classes.filesTable+" "+classes.referralsTable}
                                                getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                                getNoDataProps={() => this.getNoDataProps()}
                                            />
                                        </>
                                }
                                
                            </div>
                            <div className={classes.loadMore}>
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadReferrals(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Your link has been copied</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
            </div>
        )
    }
}

Referrals.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userReferralsPageStyle)(Referrals);
