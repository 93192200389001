import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Sidebar from "../../components/User/AccountSidebar";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import MaterialIcon from "@mdi/react";
import { mdiArrowDownCircleOutline } from '@mdi/js';
import logo from "../../../assets/img/favicon.ico";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const Download = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            showError: false,
            errorMessage: "",
            deleteFileModal: false,
        }

    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }    
    }
    render() {
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.accountMainContainer}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    <div className={classes.content}>
                        <GridItem>
                            <div className={classes.downloadContainer}>
                                <h3 className={classes.title}>Download your data</h3>
                                <div className="download-section">
                                    <GridContainer>
                                        <GridItem sm={12} md={10} lg={8}>
                                            <h4>2 apps and services</h4>
                                            <p>6.52GB downloadable in 5 files</p>    
                                            <div className="download-detail">
                                                <div>
                                                    <ul>
                                                        <li><span>Date requested: </span>09/15/2020, 07:30 PM</li>
                                                        <li><span>Available until: </span>10/03/2020, 09:30 AM</li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <Button className={"delete-copy"} size="lg" simple>Delete this copy<RemoveIcon /></Button>
                                                </div>
                                            </div>    
                                            <div className="download-files">
                                                <ul className="download-files-lists">
                                                    <li>
                                                        <ul>
                                                            <li><img src={logo} alt="sendlinx-logo"/>Sendlinx Part 1 of 5</li>
                                                            <li>1.93GB<Button simple><MaterialIcon path={mdiArrowDownCircleOutline} className="MuiSvgIcon-root" /></Button></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <ul>
                                                            <li><img src={logo} alt="sendlinx-logo"/>Sendlinx Part 2 of 5</li>
                                                            <li>2.90GB<Button simple><MaterialIcon path={mdiArrowDownCircleOutline} className="MuiSvgIcon-root" /></Button></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <ul>
                                                            <li><img src={logo} alt="sendlinx-logo"/>Sendlinx Part 3 of 5</li>
                                                            <li>850.10MB<Button simple><MaterialIcon path={mdiArrowDownCircleOutline} className="MuiSvgIcon-root" /></Button></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <ul>
                                                            <li><img src={logo} alt="sendlinx-logo"/>Sendlinx Part 4 of 5</li>
                                                            <li>2GB<Button simple><MaterialIcon path={mdiArrowDownCircleOutline} className="MuiSvgIcon-root" /></Button></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <ul>
                                                            <li><img src={logo} alt="sendlinx-logo"/>Sendlinx Part 5 of 5</li>
                                                            <li>600MB<Button simple><MaterialIcon path={mdiArrowDownCircleOutline} className="MuiSvgIcon-root" /></Button></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Download.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Download);
