import React from 'react';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Sidebar from "../../components/User/Sidebar";
import Button from "../../components/CustomButtons/Button";
import Link from "@material-ui/core/Link";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import LoaderComponent from "../../components/Loader";
import UpgradeModal from "../../components/User/UpgradeModal";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

import windowIcon from "../../../assets/img/windows.jpg";
import macIcon from "../../../assets/img/mac.jpg";
import { helper } from '../../../assets/js/utils/Element.js';

const Downloads = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            totalItemCount: 0,
            response: null,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            page: 1,
            upgradeModal: false
        };   
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }else{
            if(user.account_type < 3){
                const homePage = helper.getHomePageUrl(user);
                this.history.push(homePage);
            }
        }
        this.loadDownloads();
    }
    loadDownloads(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 200,
        };
        Api.getDownloads(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            cancelToken: source,
            page: page,
        });
    }
    handleClick(e, url){
        e.preventDefault();
        const { user } = this.store.getState();

        if(user.account_type >= 3){
            window.open(url);
        }else{
            this.onUpgradeModal(true)
        }
    }
    onUpgradeModal(status){
        this.setState({
            upgradeModal: status
        })
    }
    renderDownload(download, key){
        const { classes } = this.props;
        return (
            <GridContainer key={key}>
                <GridItem xs={12} sm={3} lg={2} className={classes.imageColumn}>
                    <img src={download.thumbnail_url} alt={key} />
                </GridItem>
                <GridItem xs={12} sm={6} md={5} ld={6} className={classes.textColumn}>
                    <h3>{download.title}</h3>
                    <p>
                        {download.description}
                    </p>
                </GridItem>
                <GridItem xs={12} sm={3} md={4} className={classes.buttonColumn}>
                    <GridContainer>
                        <GridItem xs={6} sm={12} md={6} className={classes.buttonColumn}>
                            {
                                download.windows === 1 && download.windows_url !== "" ?
                                    <Link onClick={(e) => this.handleClick(e, download.windows_url)} underline="none">
                                        <img src={windowIcon} alt="window" />
                                    </Link>
                                :
                                    <Button simple>N/A</Button>
                            }
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6} className={classes.buttonColumn}>
                            {   
                                download.mac === 1 && download.mac_url !== "" ?
                                    <Link onClick={(e) => this.handleClick(e, download.mac_url)} underline="none">
                                        <img src={macIcon} alt="mac" />
                                    </Link>
                                :
                                    <Button simple>N/A</Button>
                            }
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        )
    }
    render() {
        const { response, upgradeModal } = this.state;
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.downloadsMainContainer}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    <div className={classes.content}>
                        <GridItem>
                            <div className={classes.videoContainer+" "+classes.downloadsContainer}>
                                <h3 className={classes.title}>Downloads</h3>
                                {
                                    response ?
                                        response.map((download, key) => {
                                            return this.renderDownload(download, key)
                                        })
                                    :
                                        <LoaderComponent color="custom" />
                                }
                            </div>
                        </GridItem>
                    </div>
                    {
                        upgradeModal ?
                            <UpgradeModal 
                                open={upgradeModal} 
                                onClose={() => this.onUpgradeModal(false)} 
                                message="Upgrade your account for download this app"
                            />
                        :
                        <></>
                    }
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Downloads.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Downloads);
