/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import { Link as RouerLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
import { helper } from "../../../assets/js/utils/Element";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import CustomButton from "../../components/CustomButtons/Button.jsx";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import CameraIcon from "@material-ui/icons/CameraAlt";
// core components
import headerStyle from "../../../assets/jss/material-kit-pro-react/components/headerStyle.jsx";
import logo from "../../../assets/img/logo.png";
import responsiveLogo from "../../../assets/img/logo.png";
import Popover from '@material-ui/core/Popover';
import ChartistGraph from "react-chartist";
import CustomFileInput from "../CustomFileInput/CustomFileInput";
import LoaderComponent from '../../components/Loader'
import DeleteIcon from "@material-ui/icons/DeleteSharp";
import Config from "../../../../Config";
import moment from 'moment';
import Switch from "@material-ui/core/Switch";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        this.state = {            
            mobileOpen: this.props.open,
            color: this.props.color,
            isResponsive: this.props.isResponsive,
            popupOpen: false,
            anchorEl: null,
            photo: null,
            success: false,
            uploading: false,
            user: user,
        };
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.headerColorChange = this.headerColorChange.bind(this);
        this.handlePopup = this.handlePopup.bind(this);
    }
    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }
    handlePopup(){
        this.setState({ popupOpen: !this.state.popupOpen });
    }
    componentWillReceiveProps(props){
        const { user } = this.store.getState();
        let state = {
            color: props.color,
            mobileOpen: props.open,
            isResponsive: props.isResponsive
        };
        if(this.state.user.email === '' && user.id !== ''){
            state['user'] = user;
        }
        
        this.setState(state);
        if (this.props.changeColorOnScroll) {
            const that = this;
            setTimeout(function(){ that.headerColorChange(); },100);
        }
    }
    componentDidMount() {
        if (this.props.changeColorOnScroll) {
            window.addEventListener("scroll", this.headerColorChange);
            this.headerColorChange();
        }
    }
    headerColorChange() {
        const { color, changeColorOnScroll } = this.props;
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            this.setState({color: changeColorOnScroll.color});
        } else {
            this.setState({color: color});
        }
    }
    componentWillUnmount() {
        if (this.props.changeColorOnScroll) {
            window.removeEventListener("scroll", this.headerColorChange);
        }
    }
    getUserTitle(user){
        let title = "";
        if(user.hasOwnProperty("first_name")){
            title += user.first_name;
        }
        if(user.hasOwnProperty("last_name")){
            title += " "+user.last_name;
        }
        return title;
    }
    handleClick = (event) => {
        this.setState({
            popupOpen: true,
            anchorEl: event.currentTarget
        });

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 200);
    };
    handleFileChange(e, name){
        let photo = e.target.files[0];

        var requestData = new FormData();
        requestData.append('photo', photo);

        const source = axios.CancelToken.source();
        this.setState({
            [name]: photo,
            uploading: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.uploadUserPhoto(requestData, source).then(data => {
            that.setState({
                success: true,
                uploading: false
            });
            let newUser = Api.prepareMemberObject(data);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    uploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    removeProfilePhoto(e, name){
        var requestData = {};

        const source = axios.CancelToken.source();
        this.setState({
            [name]: '',
            uploading: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        Api.removeUserPhoto(requestData, source).then(data => {
            that.setState({
                success: false,
                uploading: false
            });
            let newUser = Api.prepareMemberObject(data);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    uploading: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    handleClose = () => {
        this.setState({
            popupOpen: false,
            anchorEl: null
        })
    };
    renderStats(){
        const { user } = this.store.getState();
        if(!user.hasOwnProperty("stats")){
            return <></>
        }
        let stats = [];
        user.stats.map((stat, key) => {
            const statClassName = stat.title.toLowerCase().replace(" ","_");
            stats.push((
                <li key={key}>
                    <ul>
                        <li className="stat-name"><span className={statClassName}></span> {stat.title}</li>
                        <li className="stat-perc">{stat.storage_used}%</li>
                    </ul>
                </li>
            ));
            return null;
        });
        return stats;
    }
    getPieChart(){
        const { user } = this.store.getState();
        const quota = user.quota;
        const usage = user.usage;
        let usagePercentage = (usage/quota)*100;
        usagePercentage = Math.round(usagePercentage * 10) / 10;
        if(usagePercentage > 100){
            usagePercentage = 100;
        }
        let remainingPercentage = 100 - usagePercentage;
        if(remainingPercentage < -100){
            remainingPercentage = -100;
        }
        if(helper.isFloat(usagePercentage)){
            usagePercentage = usagePercentage.toFixed(1);
        }
        if(helper.isFloat(remainingPercentage)){
            remainingPercentage = remainingPercentage.toFixed(1);
        }
        let pieChart = {
            data: {
                labels: [usagePercentage+"%", remainingPercentage+"%"],
                series: [usagePercentage, remainingPercentage]
            },
            options: {
                chartPadding: 0,
                height: 67,
                width: 67
            }
        };
        return pieChart;
    }
    getLogo(){
        const { user } = this.store.getState();
        const { isResponsive } = this.props;

        if(user.hasOwnProperty('businessUser') && user.businessUser !== null && user.businessUser.profile_photo_url !== ''){
            return user.businessUser.profile_photo_url
        }else{
            return isResponsive ? responsiveLogo : logo
        }
    }
    renderScheduled(){
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return null;
        }
        if(!user.hasOwnProperty("scheduled_deletion") || !user.scheduled_deletion 
        || !user.hasOwnProperty("scheduled_deletion_at") || !user.scheduled_deletion_at || user.scheduled_deletion_at.length <= 0){
            return null;
        }
        const { classes } = this.props;
        const deletionDate = moment(user.scheduled_deletion_at);
        const currentDate = moment(new Date());
        const duration = moment.duration(deletionDate.diff(currentDate));
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        return (
            <span className={classes.scheduledDeletion}>
                Your account is in the process of being deleted in{" "}
                {
                    days > 0 ?
                        days > 1 ? (days+" days") : (days+" day")
                    :
                        hours > 0 ?
                            hours > 1 ? (hours+" hours") : (hours+" hour")
                        :
                            minutes > 1 ? (minutes+" minutes") : (minutes+" minute")
                }
            </span>
        )
    }
    handleThemeToggle(e, name){  
        this.setState({
            user: {
                ...this.state.user,
                [name]: e.target.checked ? 'standard' : 'light'
            }
        },() => {
            this.updateUserSettings()
        });
    }
    updateUserSettings(){
        const source = axios.CancelToken.source();
        const { user } = this.state;
        const requestData = {
            theme: user.theme
        }
        
        Api.updateUserSettings(requestData, source).then(data => {
            
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });

        localStorage.setItem("sendlinx_userinfo", JSON.stringify(user));
        let newUser = Api.prepareMemberObject(user);
        this.store.dispatch({type: "UPDATE_STATE",state: {user: newUser}});
    }
    render() {
        const { classes, mobileLinks, links, brand, fixed, absolute } = this.props;
        const { authorized, user } = this.store.getState();
        const { color, popupOpen, anchorEl, photo, uploading, success } = this.state;        

        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes[color]]: color,
            [classes.absolute]: absolute,
            [classes.fixed]: fixed
        });
        
        let userFirstLetter = user.first_name.charAt(0);
        const pieChart = this.getPieChart();
        const id = popupOpen ? 'simple-popover' : undefined;
        return (
            <AppBar className={appBarClasses}>
                <Toolbar className={classes.container}>
                    <RouerLink to={(authorized ? helper.getHomePageUrl(user) : "/")} className={classes.brand}>
                        <img src={this.getLogo()} alt={brand} />
                    </RouerLink>
                    {this.renderScheduled()}
                    <Hidden smDown implementation="css" className={classes.hidden}>
                        <div className={classes.collapse}>{links}</div>
                    </Hidden>
                    {
                        !authorized || (authorized && user.level_id === 5) ?
                            <Hidden mdUp>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.handleDrawerToggle}
                                    >
                                    <Menu />
                                </IconButton>
                            </Hidden>
                        :
                            <></>
                    }
                    
                    {
                        authorized ?
                            <>
                                <div className={classes.switchLayoutIcon} title="Theme Layout">
                                    <Switch
                                        checked={(this.state.user.theme === 'standard' ? true : false)}
                                        onChange={(e) => this.handleThemeToggle(e, 'theme')}
                                        value="theme"
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                </div>
                                <IconButton
                                    className={classes.userFirstLetter}
                                    color="inherit"
                                    aria-label="open popup"
                                    // onClick={this.handlePopup}
                                    variant="contained"
                                    onClick={this.handleClick}
                                    aria-describedby={id}
                                >
                                    <span className="user-icon">
                                        {
                                            user.icon_photo_url ?
                                                <img src={user.icon_photo_url} className="profile-picture" alt={userFirstLetter} />
                                            :
                                                userFirstLetter
                                        }
                                    </span>
                                </IconButton>
                                <Popover
                                    id={id}
                                    open={popupOpen}
                                    anchorEl={anchorEl}
                                    onClose={this.handleClose}
                                    keepMounted
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 40,
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    classes={{
                                        root: classes.popoverRoot
                                    }}
                                >
                                    <div className={classes.popupContent}>
                                        <div className={"top-content"}>
                                            <div className={classes.userFirstLetter+" user-image"}>
                                                <span className="user-icon">
                                                    {
                                                        uploading === true ?
                                                            <LoaderComponent color="custom" align="center" />
                                                        : success === true || user.icon_photo_url ?
                                                            <img className={"profile-picture"} src={user.icon_photo_url} alt={userFirstLetter} />
                                                        : 
                                                            userFirstLetter   
                                                    }
                                                </span>
                                                {/* <IconButton><CameraIcon/></IconButton> */}
                                                <div className="uploadPhotoInput">
                                                    <CustomFileInput
                                                        id="input-photo"                            
                                                        onChange={(e) => this.handleFileChange(e,'photo')}
                                                        endButton={{
                                                            buttonProps: {
                                                                round: true,
                                                                color: "custom",
                                                                justIcon: true,
                                                                fileButton: true
                                                            },
                                                            icon: <CameraIcon />
                                                        }}
                                                        inputProps={{
                                                            value: photo,
                                                            placeholder: "Photo"
                                                        }}
                                                        color="custom"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    success === true || user.icon_photo_url ?
                                                        <div className={"removePhoto"}>
                                                            <CustomButton color="custom" justIcon={true} round={true} size={"sm"} onClick={(e) => this.removeProfilePhoto(e, 'photo')}><DeleteIcon /></CustomButton>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                            <h4 className="user-name">{this.getUserTitle(user)}</h4>
                                            <p className="user-email">{user.email}</p>
                                            {
                                                Config.isLimitedAccess() === false ?
                                                    <RouerLink to={"/user/account"} onClick={this.handleClose}>
                                                        <CustomButton className={"user-manage"} round color="white">Manage your Sendlinx Account</CustomButton>
                                                    </RouerLink>
                                                :
                                                   <></>
                                            }
                                            {
                                                user.level_id !== 5 ?
                                                    <div className="stats-section">
                                                        <div className="left-stats">
                                                            <div className="graph-stats">
                                                                <ChartistGraph
                                                                    data={pieChart.data}
                                                                    type="Pie"
                                                                    options={pieChart.options}
                                                                />
                                                            </div>
                                                            <div className="data-detail">
                                                                <span className="used-data">{helper.getFormatedSize(user.usage)}</span>
                                                                <span className="remaining-data">{helper.getFormatedSize(user.quota)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="right-stats">
                                                            <ul>
                                                                {this.renderStats()}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                :
                                                    <></>
                                            }
                                            
                                            <div className="signup-btn">
                                                <RouerLink to={"/auth/logout"} onClick={this.handleClose}>
                                                    <CustomButton className={"user-manage"} color="white">Sign out</CustomButton>
                                                </RouerLink>
                                            </div>
                                        </div>
                                        <div className="bottom-content">
                                            <ul>
                                                <li><Link href="https://sendlinx.com/privacy-policy/" target="_blank" onClick={this.handleClose}>Privacy Policy</Link></li>
                                                <li><Link href="https://sendlinx.com/terms-of-use/" target="_blank" onClick={this.handleClose}>Terms of Use</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Popover>
                            </>
                        :
                            <></>
                    }
                </Toolbar>
                <Hidden mdUp implementation="js">
                    <Drawer
                        variant="temporary"
                        anchor={"right"}
                        open={this.state.mobileOpen}
                        classes={{
                        paper: classes.drawerPaper
                        }}
                        onClose={this.handleDrawerToggle}
                    >
                        <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.handleDrawerToggle}
                        className={classes.closeButtonDrawer}
                        >
                        <Close />
                        </IconButton>
                        <div className={classes.appResponsive}>{mobileLinks}</div>
                    </Drawer>
                </Hidden>
            </AppBar>
        );
    }
}

Header.defaultProp = {
  color: "white",
  open: false,
  updateDrawer: function(){ },
  desktop: false
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    links: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    open: PropTypes.bool,
    updateDrawer: PropTypes.func,
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
        ]).isRequired
    }),
    desktop: PropTypes.bool
};

export default withStyles(headerStyle)(Header);
