import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Sidebar from "../../components/User/AccountSidebar";
import Button from "../../components/CustomButtons/Button";
import { Link as RouerLink } from "react-router-dom";
import Link from '@material-ui/core/Link';
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
import PortalSections from "./portal/PortalSections";

import HelpIcon from "@material-ui/icons/HelpOutline";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswerOutlined";

const Account = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }
    }
    getUserTitle(user){
        let title = "";
        if(user.hasOwnProperty("first_name")){
            title += user.first_name;
        }
        if(user.hasOwnProperty("last_name")){
            title += " "+user.last_name;
        }
        return title;
    }
    renderProfile(){
        const { user } = this.store.getState();
        let userFirstLetter = user.first_name.charAt(0);
        return (
            <div className="profile-section">
                <h4 className="section-title">Profile</h4>
                <div className="section-content profile-section-content">
                    <div className="user-image">
                        <span className="user-icon">
                            {
                                user.profile_photo_url ?
                                    <img src={user.profile_photo_url} className="profile-picture" alt={userFirstLetter} />                                    
                                :
                                    userFirstLetter
                            }
                        </span>
                    </div>
                    <h4 className="user-name">{this.getUserTitle(user)}</h4>
                    <p className="user-email">{user.email}</p>
                    <RouerLink to={"/user/profile"}>
                        <Button color="custom" className={"user-manage"} round >Profile</Button>
                    </RouerLink>
                </div>
            </div>
        )
    }
    renderResources(){
        return (
            <>
                <div className="resources-section">
                    <h4 className="section-title">Helpful resources</h4>
                    <div className="resources-section-content">
                        <div className="resources-content">
                            <div className="resources-icon">
                                <HelpIcon/>
                            </div>
                            <div className="resources-text">
                                <Link href="https://sendlinx.com/support-help-faq/" target="_blank">Help Center</Link>
                                <p>Troubleshooting and how-tos</p>
                            </div>
                        </div>
                        <div className="resources-content">
                            <div className="resources-icon">
                                <QuestionAnswerIcon/>
                            </div>
                            <div className="resources-text">
                                <RouerLink to={"/user/settings"}></RouerLink>
                                <Link href="https://sendlinx.com/" target="_blank">Ask the community</Link>
                                <p>Sendlinx Support Community</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tasks-section">
                    <h4 className="section-title">Common tasks</h4>
                    <div className="tasks-section-content">
                        <ul>
                            <li><RouerLink to={"/user/download"}>View and download my apps</RouerLink></li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
    render() {
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        const { location } = this.history;
        
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.accountMainContainer}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    <div className={classes.content}>
                        <GridItem>
                            <div className={classes.accountContainer}>
                                <h3 className={classes.title}>Account Management</h3>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} lg={3} className={classes.ProfileColumn}>
                                        { this.renderProfile() }
                                    </GridItem>
                                    <GridItem xs={12} sm={6} lg={4} className={classes.PortalColumn}>
                                        <PortalSections store={this.store} history={this.history} section={location.section} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} lg={5} className={classes.linksColumn}>
                                        { this.renderResources() }
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Account.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Account);
