import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Sidebar from "../../components/User/AccountSidebar";
import GridItem from "../../components/Grid/GridItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import DatePicker from "../../components/CustomInput/DatePicker";
import SuccessModal from  "../../components/User/SuccessModal";
import LoaderComponent from "../../components/Loader";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
import Button from "../../components/CustomButtons/Button";

const Export = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            values: this.getValuesObj(),
            validation: {
                web: '',
                isValid: false
            },
            saving: false,
            successModal: false,
        }
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }    
    }
    getValuesObj(){
        let values = {
            web: false,
            desktop: false,
            outlook: false,
            mydocs: false,
            keepRecords: false,
            dateRange: false,
            maxSize: 1,
            startDate: "",
            endDate: "",
        };

        return values;
    }
    sizeOptions(){
        let options = [
            {
                label: "1GB",
                value: "1"
            },
            {
                label: "2GB",
                value: "2"
            },
            {
                label: "5GB",
                value: "5"
            },
            {
                label: "10GB",
                value: "10"
            },
            {
                label: "25GB",
                value: "25"
            }
        ];

        return options;
    }
    handleSelectChange(e, name) {
        this.setState({
            values: {
                ...this.state.values, 
                [name]:  e.target.value
            }
        })
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                values: {
                    ...this.state.values, 
                    [name]: parsedDate.toISOString()
                }
           });
        }catch(e){ console.log(e); }        
    }
    handleCheckbox(e, name){
        this.setState({
            values: {
                ...this.state.values, 
                [name]:  e.target.checked
            }
        });
    }
    filesExport(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        let values = this.getValuesObj();
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});

        Api.filesExport(this.state.values, source).then((data) => {
            this.setState({
                saving: false,
                successModal: true,
                values: values,
            })
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });
    }
    validateForm(){
        const { values } = this.state;
        let validation = {
            web: "success",
            isValid: true
        };
        if(values.web === false && values.desktop === false && values.outlook === false && values.mydocs === false){
            validation.web = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    onSuccessModal(status = false){
        this.setState({
            successModal: status,
        })
    }
    render() {
        const { values, validation, successModal, saving } = this.state;
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.accountMainContainer}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    <div className={classes.content}>
                        <GridItem>
                            <div className={classes.exportContainer}>
                                <h3 className={classes.title}>Export Requests</h3>
                                <div className={"export-section"}>
                                    <div className={(validation.web === "error" ? "error" : "")}>
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Checkbox                                                
                                                    tabIndex={-1}
                                                    onClick={(e) => this.handleCheckbox(e, "web")}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                                    checked={values.web}
                                                />
                                            }
                                            label={"Web - Files sent through the web portal."}
                                        />
                                    </div>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Checkbox                                                
                                                tabIndex={-1}
                                                onClick={(e) => this.handleCheckbox(e, "desktop")}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{checked: classes.checked, root: classes.checkRoot}}
                                                checked={values.desktop}
                                            />
                                        }
                                        label={"Desktop - Files sent using our desktop App."}
                                    />
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Checkbox                                                
                                                tabIndex={-1}
                                                onClick={(e) => this.handleCheckbox(e, "outlook")}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{checked: classes.checked, root: classes.checkRoot}}
                                                checked={values.outlook}
                                            />
                                        }
                                        label={"Outlook - Files sent through the Outlook App."}
                                    />
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Checkbox                                                
                                                tabIndex={-1}
                                                onClick={(e) => this.handleCheckbox(e, "mydocs")}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{checked: classes.checked, root: classes.checkRoot}}
                                                checked={values.mydocs}
                                            />
                                        }
                                        label={"My Docs - Files uploaded directly into the My Docs folder."}
                                    />
                                    <div className="date-range-section">
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Checkbox                                                
                                                    tabIndex={-1}
                                                    onClick={(e) => this.handleCheckbox(e, "dateRange")}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                                    checked={values.dateRange}
                                                />
                                            }
                                            label={"Date Range"}
                                        />
                                        <DatePicker
                                            labelText={"From"}
                                            id="input-startDate"
                                            value={values.startDate}
                                            onChange={(date) => this.handleDateChange(date, 'startDate')}
                                            inputProps={{
                                                disabled: !values.dateRange
                                            }}
                                            formControlProps={{
                                                className: classes.customFormControlClasses
                                            }}
                                        />
                                        <DatePicker
                                            labelText={"To"}
                                            id="input-endDate"
                                            value={values.endDate}
                                            onChange={(date) => this.handleDateChange(date, 'endDate')}
                                            inputProps={{
                                                disabled: !values.dateRange
                                            }}
                                            formControlProps={{
                                                className: classes.customFormControlClasses
                                            }}
                                        />
                                    </div>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Checkbox                                                
                                                tabIndex={-1}
                                                onClick={(e) => this.handleCheckbox(e, "keepRecords")}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{checked: classes.checked, root: classes.checkRoot}}
                                                checked={values.keepRecords}
                                            />
                                        }
                                        label={"Keep Records of exports to delete data afterwards (7 days)."}
                                    />
                                    <p>Maximum compressed file size for your download:</p>
                                    <div className="size-section">
                                        <CustomSelect 
                                            formControlProps={{
                                                fullWidth: false,
                                                className: "custom-select"
                                            }}
                                            selectProps={{
                                                onChange: (e) => this.handleSelectChange(e,"maxSize"),
                                                value: values.maxSize
                                            }}
                                            inputProps={{
                                                name: "maxSize",
                                                id: "input-maxSize",
                                                className: classes.alignLeft
                                            }}
                                            color="custom"
                                            items={this.sizeOptions()}
                                            itemLabel="label"
                                            itemValue="value"
                                        />
                                        <div className="size-notes">
                                            <ul>
                                                <li>* We will divide your data into files of this size or smaller.</li>
                                                <li>* You will be notified via email once exports are ready to be downloaded.</li>
                                                <li>* Exports files will be available for 30 days.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="request-button">
                                        {
                                            saving === false ?
                                                <Button color="custom"  className={"user-manage"} onClick={() => this.filesExport()}>Request</Button>
                                            :
                                                <Button color="custom">
                                                    <LoaderComponent color="white" align="center" saving={true} />
                                                </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                successModal === true ? 
                                    <SuccessModal 
                                        open={successModal} 
                                        onClose={() => this.onSuccessModal(false)} 
                                        successMessage={"Your files export request has been generated successfully!"}
                                    />
                                :
                                    <></>
                            }
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Export.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Export);
