import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "../CustomButtons/Button.jsx";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Menu from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

import sidebarStyle from "../../../assets/jss/sidebarStyle";

class SidebarBusiness extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            isActive: false
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }
    getUsersMenu(){
        const { classes } = this.props;
        let menuItems = [];
        menuItems.push(<RouterLink to={"/users"}>
            <Button color="transparent" className={classes.navLink}>Active Users</Button>
        </RouterLink>);
        return menuItems;
    }
    toggleMenu(){
        this.setState({
            isActive: !this.state.isActive
        })
    }
    render(){
        let isDesktop = isWidthUp('md', this.props.width);
        const { classes } = this.props;
        const {isActive} = this.state;

        return (
                <div className={classes.container}>
                    {
                        isDesktop === false ?
                            <div className={classes.toggleMenu}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.toggleMenu}
                                >
                                    <Menu />
                                </IconButton>
                            </div>
                        :
                        <></>
                    }
                    <div className={(isDesktop ? "" : (classes.collapse+" "+(isActive ? "active": "")))}>
                        <List className={classes.list + " " + classes.mlAuto}>
                            <ListItem className={classes.listItem}>
                                <RouterLink to="/users">
                                    <Button color="transparent" className={classes.navLink}>
                                        Users
                                    </Button>
                                </RouterLink>
                                <List className={classes.list + " " + classes.mlAuto}>
                                    {
                                        this.getUsersMenu().map((item, key) => {
                                            return (
                                                <ListItem key={key} className={classes.listItem}>
                                                    {item}
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                                <RouterLink to="/attachment/rules">
                                    <Button color="transparent" className={classes.navLink}>
                                        File Attachment Rules
                                    </Button>
                                </RouterLink>
                                <RouterLink to="/videos">
                                    <Button color="transparent" className={classes.navLink}>
                                        Login Videos
                                    </Button>
                                </RouterLink>
                            </ListItem>
                        </List>
                    </div>
                </div>
        )
    }
}

SidebarBusiness.defaultProps = {
    align: "center",
    color: "primary"
}
SidebarBusiness.propTypes = {
    classes: PropTypes.object
};

export default withWidth()(withStyles(sidebarStyle)(SidebarBusiness));